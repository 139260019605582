import { useState, useEffect, useCallback } from "react";
import { useMsal, useIsAuthenticated as useIsAuthenticatedOrig } from "@azure/msal-react";
import env from "@beam-australia/react-env";
import {
    AuthenticationResult,
    Configuration,
    EndSessionRequest,
    EventMessage,
    EventType,
    IPublicClientApplication,
    PublicClientApplication,
} from "@azure/msal-browser";

const tenant = env("B2C_TENANT");
export const scopes = (env("B2C_LOGIN_SCOPES") || "")
    .split(",")
    .map(scope => `https://${tenant}.onmicrosoft.com/api-mgt/${scope}`);
export const authority = `https://${env("B2C_TENANT")}.b2clogin.com/${env("B2C_TENANT_ID")}/${env(
    "B2C_SIGN_IN_POLICY"
)}`;

export const useIsAuthenticated = useIsAuthenticatedOrig;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: env("B2C_CLIENT_ID"),
        authority,
        knownAuthorities: [authority],
        redirectUri: `${window.location.origin}${env("ROUTER_BASE_PATH")}/redirect`,
        postLogoutRedirectUri: `${window.location.origin}${env("ROUTER_BASE_PATH")}/loggedout`,
    },
    cache: {
        // change back to localStorage once the logout button is back
        // and make sure the sws- and e2e-test implications are handled first!
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        // default token expiry times: idToken: 1h, refreshToken: 24h
        // on acquireTokenSilent calls, may renew idToken <offset> seconds
        // before expiry, using valid refreshToken.
        tokenRenewalOffsetSeconds: 600,
        // internal navigation client is set in Auth.tsx
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

/**
 * @deprecated This should have been in a lower layer all along.
 */
export const useAccessToken = (): string | null => {
    const msal = useMsal();
    const [token, setToken] = useState<string | null>(null);
    useEffect(() => {
        void (async () => {
            setToken(await getAccessTokenFromInstance(msal.instance));
        })();
    });
    return token;
};

export const getAccessToken = async (): Promise<string> => {
    const token = await getAccessTokenFromInstance(msalInstance);
    if (token === null)
        throw new Error(
            "User isn't authenticated. This code should only be called when we are sure they are"
        );
    return token;
};

const getAccessTokenFromInstance = async (instance: IPublicClientApplication) => {
    const account = instance.getActiveAccount();
    if (!account) return null;
    const result = await instance.acquireTokenSilent({ account, scopes });
    return result.accessToken;
};

export const useCompanyId = (): string | undefined => {
    const msal = useMsal();
    const account = msal.instance.getActiveAccount();
    return (account?.idTokenClaims as any)?.extension_identity_company;
};

export const useUserId = (): string | undefined => {
    const msal = useMsal();
    const account = msal.instance.getActiveAccount();
    return (account?.idTokenClaims as any)?.extension_identity_userid;
};

export const useUserName = (): string | undefined => {
    const msal = useMsal();
    const account = msal.instance.getActiveAccount();
    return account?.name;
};

export const getHcpCountry = (): string | undefined => {
    const account = msalInstance?.getActiveAccount();
    const tokenClaims = account?.idTokenClaims;
    if (tokenClaims) return tokenClaims.extension_identity_customer_country as string;
    return undefined;
};

export const useLogout = () => {
    const msal = useMsal();
    return useCallback(
        (options?: EndSessionRequest) => msal.instance.logout(options),
        [msal.instance]
    );
};
