import React, { Component } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai } from "./ApplicationInsights";
import {} from "react-router-dom";
import Telemetry from "./Telemetry";

interface TelemetryProviderProps {
    instrumentationKey: string;
    children: React.ReactNode;
}

interface TelementryProviderState {
    telemetry: Telemetry;
    initialized: boolean;
}

export const TelemetryContext = React.createContext<Telemetry>(new Telemetry(null));

class TelemetryProvider extends Component<TelemetryProviderProps, TelementryProviderState> {
    constructor(props: TelemetryProviderProps) {
        super(props);
        this.state = {
            initialized: false,
            telemetry: new Telemetry(null),
        };
    }

    componentDidMount() {
        const { instrumentationKey } = this.props;
        const { initialized } = this.state;
        if (!initialized && instrumentationKey) {
            const applicationInsights = ai.initialize(instrumentationKey);
            this.setState({
                initialized: true,
                telemetry: new Telemetry(applicationInsights),
            });
        }
    }

    render() {
        const { children } = this.props;
        return (
            <TelemetryContext.Provider value={this.state.telemetry}>
                {children}
            </TelemetryContext.Provider>
        );
    }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);
