import React from "react";
import { QueryClientProvider as ReactQueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

interface QueryProps {
    children: React.ReactNode;
}

const QueryClientProvider: React.FC<QueryProps> = ({ children }) => (
    <ReactQueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </ReactQueryClientProvider>
);

export default QueryClientProvider;
