import axios from "axios"
import { onFulfilledRequest, onFulfilledResponse, onRejectedRequest, onRejectedResponse, addErrorResponseEventListener } from "./axiosInterceptors";
import { appDispatch } from "../src/index.wrapper";
import { showNotificationBar } from "./BoundedContext/FineTuning/State/notificationReducer";
import { t } from "./Infrastructure/Services/LocalizationService";

// Add a request interceptor
axios.interceptors.request.use(onFulfilledRequest, onRejectedRequest);

// Add a response interceptor
axios.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

addErrorResponseEventListener((error: any) => {
    // When the api usage have been sanitized, remove this guard.
    if (error.response?.status !== 403 && error.response?.status !== 404 && error.response?.status !== 501) {
        appDispatch(
            showNotificationBar({
                title: t("common:generalErrorTitle"),
                severity: "error",
                message: t("common:generalErrorDescription"),
            })
        );
    }
});

let loaded = false;
if (process.env.REACT_APP_BRANDID === "Widex") {
    loaded = true;
    import("./Widex");
}

if (!loaded) {
    throw new Error("Unrecognized brand id: " + process.env.REACT_APP_BRANDID);
}
