import { InsertionGainLevel } from "./Levels";

export interface CellState {
    level: InsertionGainLevel;
    channelIndex: number;
}

export type BandGroup = "low" | "medium" | "high";

export const GroupBandsDefinition = {
    low: { lower: 0, upper: 4 },
    medium: { lower: 5, upper: 10 },
    high: { lower: 11, upper: 14 },
};
