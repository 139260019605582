import { createStore, combineReducers, applyMiddleware, Action, compose } from "redux";
import thunk, { ThunkAction, ThunkMiddleware } from "redux-thunk";
import notificationBarReducer from "./BoundedContext/FineTuning/State/notificationReducer";
import patientProfileReducer from "./BoundedContext/PatientProfile/State";
import sessionReducer from "./BoundedContext/PatientManagement/State/reducers";
import deviceReducer from "./State/Devices/reducers";
import performanceGraphReducer from "./BoundedContext/performanceGraphs/State/reducers";
import { widexFineTuningReducer } from "./BoundedContext/FineTuning/State/reducers";

export const rootReducer = combineReducers({
    widexFineTuning: widexFineTuningReducer,
    notificationBar: notificationBarReducer,
    patientProfile: patientProfileReducer,
    sessionReducer,
    devices: deviceReducer,
    performanceGraph: performanceGraphReducer,
});

const composeEnhancers: typeof compose =
    (process.env.NODE_ENV === "development" &&
        typeof window !== "undefined" &&
        // eslint-disable-next-line no-underscore-dangle
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export type RootState = ReturnType<typeof rootReducer>;

export type SyncAppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export type AppThunk<ReturnType = void> = ThunkAction<
    Promise<ReturnType>,
    RootState,
    unknown,
    Action<string>
>;

export const initialState: RootState = rootReducer(undefined as any, {} as any);

export const createRootStore = (options?: { enableLogging?: boolean }) => {
    const thunkMiddleware: ThunkMiddleware<RootState> = thunk;
    const middlewares = [thunkMiddleware] as const;
    const appliedMiddlewares = applyMiddleware(...middlewares);
    return createStore(rootReducer, initialState, composeEnhancers(appliedMiddlewares));
};
