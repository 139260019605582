import { combineReducers } from "redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EarSide } from "../../../Common/Types/EarSide";
import { InsertionGainLevel } from "../Models/Levels";
import { CellState, BandGroup, GroupBandsDefinition } from "../Models/Matrix";

export interface GainSelectionPayload {
    earSide: EarSide;
    disabledBands: number[];
    lockDisabledBandAdjustment: boolean;
}

export interface BandGroupSelectionPayload extends GainSelectionPayload {
    bandGroup: BandGroup;
}

export interface LevelGroupSelectionPayload extends GainSelectionPayload {
    level: InsertionGainLevel;
}

export interface SelectSingleFrequencyBandPayload extends GainSelectionPayload {
    channelIndex: number;
}

export interface SingleCellSelectionPayload extends GainSelectionPayload {
    channelIndex: number;
    level: InsertionGainLevel;
}

export interface FreeHandSelectionPayload {
    earSide: EarSide;
    cellState: CellState;
    disabledBands: number[];
    lockDisabledBandAdjustment: boolean;
}

export interface SetlockDisabledBandAdjustmentConfigurationPayload {
    earSide: EarSide;
    lockDisabledBandAdjustment: boolean;
}

interface FineTuningGainSelection {
    earSide: EarSide | null;
    cellStates: CellState[];
    cellSelectionActive: boolean;
    lockDisabledBandAdjustment: boolean;
}

const fineTuningGainInitialState: FineTuningGainSelection = {
    earSide: null,
    cellStates: [],
    cellSelectionActive: false,
    lockDisabledBandAdjustment: true,
};

const isCellWithTheGivenBandSelectable = (channelIndex: number, disabledBands: number[]) =>
    disabledBands.length === 0 || !disabledBands.some(band => band === channelIndex);

const isCellSelectable = (
    channelIndex: number,
    disabledBands: number[],
    level: InsertionGainLevel,
    cellStates: CellState[],
    lockDisabledBandAdjustment: boolean
) =>
    !(
        (!lockDisabledBandAdjustment && disabledBands.some(band => band === channelIndex)) ||
        cellStates.some(
            (cell: CellState) => cell.channelIndex === channelIndex && cell.level === level
        )
    );

const createSingleFrequencyBandSelection = (
    channelIndex: number,
    disabledBands: number[],
    lockDisabledBandAdjustment: boolean
): CellState[] => {
    if (!lockDisabledBandAdjustment && disabledBands.includes(channelIndex)) return [];
    return Object.keys(InsertionGainLevel).map(
        igLevel =>
            ({
                channelIndex,
                level: igLevel,
            } as CellState)
    );
};

const createLevelGroupSelection = (
    selectedLevelGroupPayload: LevelGroupSelectionPayload
): CellState[] => {
    return [...Array(15).keys()]
        .filter(
            freq =>
                !(
                    selectedLevelGroupPayload.lockDisabledBandAdjustment &&
                    selectedLevelGroupPayload.disabledBands.some(band => band === freq)
                )
        )
        .map<CellState>(channelIndex => ({
            level: selectedLevelGroupPayload.level,
            channelIndex,
        }));
};

const createAllSelection = (
    disabledBands: number[],
    earSide: EarSide,
    lockDisabledBandAdjustment: boolean
): CellState[] =>
    Object.values(InsertionGainLevel).reduce((result, level) => {
        const selectedCell = createLevelGroupSelection({
            level,
            disabledBands,
            earSide,
            lockDisabledBandAdjustment,
        });

        result.push(...selectedCell);
        return result;
    }, [] as CellState[]);

const createGroupOfBands = (
    selectedBandGroup: BandGroupSelectionPayload,
    lockDisabledBandAdjustment: boolean
) => {
    const groupDefinition = GroupBandsDefinition[selectedBandGroup.bandGroup];
    const groupOfBands: CellState[] = [];

    const levelKeys = Object.keys(InsertionGainLevel) as (keyof typeof InsertionGainLevel)[];

    for (const key of levelKeys) {
        for (
            let channelIndex = groupDefinition.lower;
            channelIndex <= groupDefinition.upper;
            channelIndex++
        ) {
            if (
                !lockDisabledBandAdjustment ||
                isCellWithTheGivenBandSelectable(channelIndex, selectedBandGroup.disabledBands)
            ) {
                groupOfBands.push({
                    level: key as InsertionGainLevel,
                    channelIndex,
                });
            }
        }
    }
    return groupOfBands;
};

const fineTuningGainSelectionSlice = createSlice({
    name: "fineTuningGainSelection",
    initialState: fineTuningGainInitialState,
    reducers: {
        bandGroupSelection(state, action: PayloadAction<BandGroupSelectionPayload>) {
            state.earSide = action.payload.earSide;
            state.cellStates = createGroupOfBands(
                action.payload,
                action.payload.lockDisabledBandAdjustment
            );
        },
        levelGroupSelection(state, action: PayloadAction<LevelGroupSelectionPayload>) {
            state.earSide = action.payload.earSide;
            state.cellStates = createLevelGroupSelection(action.payload);
        },
        selectSingleFrequencyBand(state, action: PayloadAction<SelectSingleFrequencyBandPayload>) {
            state.earSide = action.payload.earSide;
            state.cellStates = createSingleFrequencyBandSelection(
                action.payload.channelIndex,
                action.payload.disabledBands,
                action.payload.lockDisabledBandAdjustment
            );
        },
        resetCellStates(state, _: PayloadAction) {
            state.earSide = null;
            state.cellStates.length = 0;
            state.cellSelectionActive = false;
        },
        allSelection(state, action: PayloadAction<GainSelectionPayload>) {
            state.earSide = action.payload.earSide;
            state.cellStates = createAllSelection(
                action.payload.disabledBands,
                action.payload.earSide,
                action.payload.lockDisabledBandAdjustment
            );
        },
        singleCellSelection(state, action: PayloadAction<SingleCellSelectionPayload>) {
            state.cellSelectionActive = true;
            state.earSide = action.payload.earSide;
            state.cellStates = [
                { channelIndex: action.payload.channelIndex, level: action.payload.level },
            ];
        },
        stopCellSelection(state, _: PayloadAction) {
            state.cellSelectionActive = false;
        },
        freeHandSelection(state, action: PayloadAction<FreeHandSelectionPayload>) {
            if (state.cellSelectionActive && action.payload.earSide && state.cellStates) {
                state.cellStates = removePreviousSelectedCellsOutsideLimits(
                    state.cellStates[0],
                    action.payload.cellState,
                    state.cellStates
                );

                const originCellIndex = state.cellStates[0].channelIndex;
                const originCellLevel = Object.values(InsertionGainLevel).indexOf(
                    state.cellStates[0].level
                );

                const currentCellIndex = action.payload.cellState.channelIndex;
                const currentCellLevel = Object.values(InsertionGainLevel).indexOf(
                    action.payload.cellState.level
                );

                const [minLevel, maxLevel] = findLowerAndUpperLimit(
                    originCellLevel,
                    currentCellLevel
                );
                const [minIndex, maxIndex] = findLowerAndUpperLimit(
                    originCellIndex,
                    currentCellIndex
                );

                for (let level = minLevel; level <= maxLevel; level++) {
                    for (let index = minIndex; index <= maxIndex; index++) {
                        const igLevel = Object.values(InsertionGainLevel)[level];

                        if (
                            isCellSelectable(
                                index,
                                action.payload.disabledBands,
                                igLevel,
                                state.cellStates,
                                !action.payload.lockDisabledBandAdjustment
                            )
                        ) {
                            state.cellStates.push({ channelIndex: index, level: igLevel });
                        }
                    }
                }

                if (state.cellStates.length !== 1) {
                    const cellStatesToKeep = state.cellStates.filter(
                        handleFreehandSelectionOfCellsPredicates(
                            action.payload.cellState.channelIndex,
                            action.payload.cellState.level,
                            originCellIndex,
                            state.cellStates[0].level
                        )
                    );

                    state.cellStates = state.cellStates.filter(
                        (cellToCheck: CellState) =>
                            !cellStatesToKeep.some(
                                (c: CellState) =>
                                    c.level === cellToCheck.level &&
                                    c.channelIndex === cellToCheck.channelIndex
                            )
                    );
                }
            }
        },
    },
});

const igEnumIndex = (ig: InsertionGainLevel) => Object.values(InsertionGainLevel).indexOf(ig);

const handleFreehandSelectionOfCellsPredicates = (
    currentChannelIndex: number,
    currentLevel: InsertionGainLevel,
    startingChannelIndex: number,
    startingLevel: InsertionGainLevel
): ((cell: CellState) => boolean) => {
    const levelPredicateDictionary = {
        levelsBelowCurrentLevel: (c: CellState) => igEnumIndex(c.level) < igEnumIndex(currentLevel),
        levelsOtherThanCurrentLevel: (c: CellState) =>
            igEnumIndex(c.level) !== igEnumIndex(currentLevel),
        levelsHigherThanCurrentLevel: (c: CellState) =>
            igEnumIndex(c.level) > igEnumIndex(currentLevel),
    };

    const channdelIndexPredicateDict = {
        channelsWithIndexLowerThanCurrentIndex: (c: CellState) =>
            c.channelIndex < currentChannelIndex,
        channelsWithIndexOtherThanCurrentIndex: (c: CellState) =>
            c.channelIndex !== currentChannelIndex,
        channelsWithIndexHigherThanCurrentIndex: (c: CellState) =>
            c.channelIndex > currentChannelIndex,
    };

    const predicates: ((cells: CellState) => boolean)[] = [];

    if (igEnumIndex(currentLevel) < igEnumIndex(startingLevel)) {
        predicates.push(levelPredicateDictionary.levelsBelowCurrentLevel);
    } else if (igEnumIndex(currentLevel) === igEnumIndex(startingLevel)) {
        predicates.push(levelPredicateDictionary.levelsOtherThanCurrentLevel);
    } else {
        predicates.push(levelPredicateDictionary.levelsHigherThanCurrentLevel);
    }

    if (currentChannelIndex < startingChannelIndex) {
        predicates.push(channdelIndexPredicateDict.channelsWithIndexLowerThanCurrentIndex);
    } else if (currentChannelIndex === startingChannelIndex) {
        predicates.push(channdelIndexPredicateDict.channelsWithIndexOtherThanCurrentIndex);
    } else {
        predicates.push(channdelIndexPredicateDict.channelsWithIndexHigherThanCurrentIndex);
    }

    return (cell: CellState) =>
        predicates.reduce((prev: boolean, curr) => prev || curr(cell), false);
};

const removePreviousSelectedCellsOutsideLimits = (
    originCell: CellState,
    currentSelectedCell: CellState,
    cellStates: CellState[]
) => {
    const [lowerLevel, upperLevel] = findLowerAndUpperLimit(
        Object.values(InsertionGainLevel).indexOf(originCell.level),
        Object.values(InsertionGainLevel).indexOf(currentSelectedCell.level)
    );
    const [lowerIndex, upperIndex] = findLowerAndUpperLimit(
        originCell.channelIndex,
        currentSelectedCell.channelIndex
    );

    return cellStates.filter(
        cell =>
            Object.values(InsertionGainLevel).indexOf(cell.level) >= lowerLevel &&
            Object.values(InsertionGainLevel).indexOf(cell.level) <= upperLevel &&
            cell.channelIndex >= lowerIndex &&
            cell.channelIndex <= upperIndex
    );
};

const findLowerAndUpperLimit = (firstLimit: number, secondLimit: number) => {
    const lowerLimit = Math.min(firstLimit, secondLimit);
    const upperLimit = Math.max(firstLimit, secondLimit);

    return [lowerLimit, upperLimit];
};

export const {
    bandGroupSelection,
    levelGroupSelection,
    resetCellStates,
    allSelection,
    selectSingleFrequencyBand,
    singleCellSelection,
    freeHandSelection,
    stopCellSelection,
} = fineTuningGainSelectionSlice.actions;

export const widexFineTuningReducer = combineReducers({
    fineTuningGainSelection: fineTuningGainSelectionSlice.reducer,
});
