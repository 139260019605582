import React from "react";
import { logError } from "../Infrastructure/Services/LoggingService";

interface AppInsightsTrackingBoundaryProps {
    children: React.ReactNode;
}

export class AppInsightsTrackingBoundary extends React.Component<AppInsightsTrackingBoundaryProps> {
    componentDidCatch(error: Error) {
        logError(error);
    }

    render() {
        return this.props.children;
    }
}
