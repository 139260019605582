import _constant from "lodash/constant";
import _noop from "lodash/noop";
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { HearingAids } from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";
import {
    trackEvent as trackEventAI,
    stopTrackPage as stopTrackPageAI,
    startNamedTracking as startNamedTrackingAI,
    startTrackPage as startTrackPageAI,
} from "./ApplicationInsights";
import { logVerbose } from "./LoggingService";
import { TelemetryContext } from "./TelemetryProvider";

interface Props {
    children: ReactNode;
}

export const Context = createContext<{
    setFittingChoice: (newFittingChoice: string) => void;
    connectionId: string;
    getFittingChoice: () => string;
    getAssignedHIs: () => HearingAids;
    changeAssignedHA: (hearingAids: HearingAids) => void;
    changeConnectionStatus: (isLeftConnected: boolean, isRightConnected: boolean) => void;
}>({
    setFittingChoice: _noop,
    connectionId: "",
    getFittingChoice: _constant(""),
    getAssignedHIs: _constant({ left: null, right: null }),
    changeAssignedHA: _noop,
    changeConnectionStatus: _noop,
});

const HearingAidDataProvider = ({ children }: Props) => {
    const fittingChoiceRef = useRef("");
    const hearingAidDataRef = useRef<HearingAids>({ left: null, right: null });

    const [state, dispatch] = useReducer(reducer, initialState);
    const changeAssignedHA = useCallback((hearingAids: HearingAids) => {
        hearingAidDataRef.current = hearingAids;
        dispatch({ type: "hearingAidsAssignmentChanged", payload: hearingAids });
    }, []);
    const changeConnectionStatus = useCallback(
        (isLeftConnected: boolean, isRightConnected: boolean) =>
            dispatch({
                type: "hearingAidsConnectionChanged",
                payload: { isLeftConnected, isRightConnected },
            }),
        []
    );

    const setFittingChoice = useCallback((newFittingChoice: string) => {
        fittingChoiceRef.current = newFittingChoice;
    }, []);

    const getFittingChoice = useCallback(() => fittingChoiceRef.current, []);

    const getAssignedHIs = useCallback(() => hearingAidDataRef.current, []);

    const value = useMemo(
        () => ({
            setFittingChoice,
            getFittingChoice,
            getAssignedHIs,
            connectionId: state.connectionId,
            changeAssignedHA,
            changeConnectionStatus,
        }),
        [
            changeAssignedHA,
            changeConnectionStatus,
            getFittingChoice,
            getAssignedHIs,
            setFittingChoice,
            state.connectionId,
        ]
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useTrackEvent = () => {
    const telemetryContext = useContext(TelemetryContext);
    const { connectionId } = useContext(Context);

    // do not add connection id if there is no connection
    const trackEvent = useCallback(
        (eventName: string, dictionary: { [name: string]: string } = {}) => {
            logVerbose(`eventName: ${eventName} ; connectionId: ${connectionId}`);
            if (connectionId) {
                trackEventAI(telemetryContext, eventName, {
                    ...dictionary,
                    analyticsConnectionId: connectionId,
                });
            } else {
                trackEventAI(telemetryContext, eventName, dictionary);
            }
        },
        [telemetryContext, connectionId]
    );
    return { trackEvent };
};

const useStartNamedTracking = () => {
    const telemetryContext = useContext(TelemetryContext);
    const startNamedTracking = useCallback(
        (idName: string) => {
            startNamedTrackingAI(idName, telemetryContext);
        },
        [telemetryContext]
    );
    return { startNamedTracking };
};

const useStartTrackPage = () => {
    const telemetryContext = useContext(TelemetryContext);
    const startTrackPage = useCallback(
        (eventName: string) => {
            startTrackPageAI(telemetryContext, eventName);
        },
        [telemetryContext]
    );
    return { startTrackPage };
};

const useStopTrackPage = () => {
    const telemetryContext = useContext(TelemetryContext);
    const { connectionId } = useContext(Context);
    const stopTrackPage = useCallback(
        (eventName: string, dictionary: { [name: string]: string } = {}) => {
            if (connectionId) {
                stopTrackPageAI(telemetryContext, eventName, window.location.href, {
                    ...dictionary,
                    analyticsConnectionId: connectionId,
                });
            } else {
                stopTrackPageAI(telemetryContext, eventName, window.location.href, dictionary);
            }
        },
        [telemetryContext, connectionId]
    );
    return { stopTrackPage };
};

const useHearingAidData = () => useContext(Context);

export interface HearingAidDataState {
    left: {
        isConnected: boolean;
        id: string;
    };
    right: {
        isConnected: boolean;
        id: string;
    };
    connectionId: string;
}

type ACTIONTYPE =
    | { type: "hearingAidsAssignmentChanged"; payload: HearingAids }
    | {
          type: "hearingAidsConnectionChanged";
          payload: { isLeftConnected: boolean; isRightConnected: boolean };
      };

const initialState = {
    left: {
        isConnected: false,
        id: "",
    },
    right: {
        isConnected: false,
        id: "",
    },
    connectionId: "",
};

function reducer(state: HearingAidDataState, action: ACTIONTYPE): HearingAidDataState {
    const getConnectionId = (
        leftHAId: string | undefined,
        rightHAId: string | undefined,
        isLeftPhisicallyConnected: boolean,
        isRightPhisicallyConnected: boolean
    ) => {
        let connectionId = "";
        if (isLeftPhisicallyConnected) {
            connectionId = `left:${leftHAId};`;
        }
        if (isRightPhisicallyConnected) {
            connectionId = connectionId + `right:${rightHAId}`;
        }

        return connectionId;
    };

    let connectionId: string;

    switch (action.type) {
        case "hearingAidsAssignmentChanged":
            connectionId = getConnectionId(
                action.payload.left?.id,
                action.payload.right?.id,
                state.left.isConnected,
                state.right.isConnected
            );
            return {
                ...state,
                left: {
                    isConnected: state.left.isConnected,
                    id: action.payload.left?.id ?? "",
                },
                right: {
                    isConnected: state.right.isConnected,
                    id: action.payload.right?.id ?? "",
                },
                connectionId,
            };
        case "hearingAidsConnectionChanged":
            connectionId = getConnectionId(
                state.left.id,
                state.right.id,
                action.payload.isLeftConnected,
                action.payload.isRightConnected
            );
            return {
                ...state,
                left: {
                    isConnected: action.payload.isLeftConnected,
                    id: state.left.id,
                },
                right: {
                    isConnected: action.payload.isRightConnected,
                    id: state.right.id,
                },
                connectionId,
            };
        default:
            throw new Error("Invalid action type");
    }
}

export {
    HearingAidDataProvider,
    useHearingAidData,
    useTrackEvent,
    useStartNamedTracking,
    useStartTrackPage,
    useStopTrackPage,
};
