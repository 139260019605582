import React, { useContext, useEffect } from "react";
import {
    EventMessage,
    EventType,
    PublicClientApplication,
    NavigationClient,
    NavigationOptions,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import Telemetry from "./Infrastructure/Services/Telemetry";
import { trackEvent } from "./Infrastructure/Services/ApplicationInsights";
import { TelemetryContext } from "./Infrastructure/Services/TelemetryProvider";
import { msalInstance } from "./Infrastructure/Services/NexusAuthService";
import { useNavigate, NavigateFunction } from "react-router-dom";
import env from "@beam-australia/react-env";

export class CustomNavigationClient extends NavigationClient {
    private readonly navigate: NavigateFunction;
    constructor(navigate: NavigateFunction) {
        super();
        this.navigate = navigate;
    }
    /**
     * Navigates to other pages within the same web application
     * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
     * @param url
     * @param options
     */
    async navigateInternal(url: string, options: NavigationOptions) {
        const relativePath = url.replace(`${window.location.origin}${env("ROUTER_BASE_PATH")}`, "");
        if (options.noHistory) {
            this.navigate(relativePath, { replace: true });
        } else {
            this.navigate(relativePath);
        }

        return false;
    }
}

const hookupLoginEventsToTelemetry = (
    telemetryClient: Telemetry,
    clientApp: PublicClientApplication
) => {
    const callbackId = clientApp.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_START) {
            trackEvent(telemetryClient, "Login.LoginPage.Rendered");
        } else if (event.eventType === EventType.LOGIN_SUCCESS) {
            trackEvent(telemetryClient, "Login.Login.Completed");
        } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
            trackEvent(telemetryClient, "Login.Logout.Completed");
        }
    });
    return () => {
        if (callbackId) clientApp.removeEventCallback(callbackId);
    };
};

/**
 * Places the global msal instance in a react component
 * so it's accessible from the context, and allows easy
 * authentication.
 */
const Auth = (props: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const telemetryClient = useContext(TelemetryContext);
    useEffect(() => {
        const navigationClient = new CustomNavigationClient(navigate);
        msalInstance.setNavigationClient(navigationClient);
        const unregisterEvents = hookupLoginEventsToTelemetry(telemetryClient, msalInstance);
        return () => {
            unregisterEvents();
        };
    }, [telemetryClient, navigate]);

    return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
};

export default Auth;
